<template>
	<div class="authentication">
		<el-breadcrumb separator-class="el-icon-arrow-right">
  			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
  			<el-breadcrumb-item>实名认证</el-breadcrumb-item>
		</el-breadcrumb>
        <div class="authentication-box">
				<div class="top">
					<el-alert v-if="pageform.status==5" title="身份已认证成功!" type="success" :closable="false"></el-alert>
					<el-alert v-if="pageform.status==4" title="实名认证失败,请重新填写并上传!" type="error" :closable="false"></el-alert>
					<el-alert v-if="pageform.status==3" title="您提交的实名认证正在审核中,请耐心等待!" type="warning" :closable="false"></el-alert>
					<el-alert v-if="pageform.status==2" title="初审通过(有推荐码)" type="success" :closable="false"></el-alert>
					<el-alert v-if="pageform.status==1" title="您还未实名,会影响部分功能使用" type="warning" :closable="false"></el-alert>
				</div>
            <div class="middle">
                <span class="title">实名认证</span>
            </div>
            <div class="content">
                <el-form :model="ruleForm"  :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm authentication-form">
                    <el-form-item label="真实姓名" prop="truename" placeholder="请填写真实姓名" style="width:40%">
                        <el-input v-model="ruleForm.truename" :disabled='isDisabled'></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号" prop="idNo" placeholder="请填写与姓名一致的证件号" style="width:40%">
                        <el-input v-model="ruleForm.idNo" :disabled='isDisabled'></el-input>
                    </el-form-item>
                    <el-form-item label="身份证正面" prop="idFront" style="width:40%">
						<div class="front" v-if="ruleForm.auditStatus != 0">
							<a :href=" 'https://file.aleopool.top/' + ruleForm.idImgFront" target="_blank">点击查看</a>
						</div>
                        <div class="front" v-else>
                            <el-upload 
                            :limit="1"
                            :on-success="uploadSuccessF"
                            :action="uploadUrlF"
							:data="postData"
							list-type="picture-card"
							:src="ruleForm.idFront"
                            :on-remove="handleRemovefront"
                            :on-change="handleChangefront"
                            :class="{hidefront:hidefrontUploadCard}"
                            >
                                <i class="el-icon-plus"></i>
                                <span>身份证正面</span>
                                </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="ruleForm.idFront" alt="智岚博丰">
                            </el-dialog>
                            <div class="text">上传证件与姓名需要一致，JPG、PNG格式图片小于5M</div>
                    </div>
                    </el-form-item>
                    <el-form-item label="身份证反面" prop="idBehind" style="width:40%">
						<div class="back" v-if="ruleForm.auditStatus != 0">
							<a :href="'https://file.aleopool.top/' + ruleForm.idImgBehind" target="_blank">点击查看</a>
						</div>
                        <div class="back" v-else>
                            <el-upload 
                            :limit="1"
                            :on-success="uploadSuccessB"
                            :action="uploadUrlB"
							:data="postData"
                            list-type="picture-card"
                            :on-remove="handleRemoveback"
                            :on-change="handleChangeback"
                            :class="{hideback:hidebackUploadCard}"
                            >
                                <i class="el-icon-plus"></i>
                                <span>身份证反面</span>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="ruleForm.idBehind" alt="智岚博丰">
                            </el-dialog>
                            <div class="text">上传证件与姓名需要一致，JPG、PNG格式图片小于5M</div>
                        </div>
                    </el-form-item>
					<el-form-item label="审核状态"  style="width:40%">
					    <div class="status" v-if="ruleForm.auditStatus == -10">审核不通过</div>
					    <div class="status" v-if="ruleForm.auditStatus == 1">已提交待审核</div>
					    <div class="status" v-if="ruleForm.auditStatus == 10">审核通过</div>
					    <div class="reason" v-if="ruleForm.auditStatus == -10">审核失败原因:{{ruleForm.auditReason}}</div>
					</el-form-item>
                </el-form>
            </div>

            <div class="fixed-bottom" v-if="ruleForm.auditStatus == 0 || ruleForm.auditStatus == -10">
                <el-button type="primary" @click="handleRealname()">确定提交</el-button>
			</div>
        </div>
	</div>	
</template>

<script>
import { Personal } from "@/api/index";
  export default {
    data() {
      return {
		isDisabled:false,
        dialogImageUrl: '',
        dialogVisible: false,
        hidebackUploadCard: false,
        hidefrontUploadCard: false,
		ruleForm:{},
		pageform:[],
		images:[],
		rules: {
		  realname: [
		    { required: true, message: '请输入姓名', trigger: 'blur' },
		  ],
		  noid: [
		    { required: true, message: '请输入身份证号', trigger: 'blur' },
			{ pattern: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证格式不正确', trigger: 'blur' }
		  ],
		}
      };
    },
	created() {
		this.Info();
		this.isDisabled = false;
	},
    methods: {
      handleRemovefront(file, fileList) {
        this.hidefrontUploadCard = fileList.length >= 1;
		let i = file.response.data
		this.images = this.images.filter(item => item != i)
      },
      handleRemoveback(file, fileList) {
        this.hidebackUploadCard = fileList.length >= 1;
		let i = file.response.data
		this.images = this.images.filter(item => item != i)
      },
      handleChangefront (file,fileList){
        this.hidefrontUploadCard = fileList.length >= 1;
      },
      handleChangeback (file,fileList){
        this.hidebackUploadCard = fileList.length >= 1;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
	  uploadSuccessF(response, file, fileList){
	      this.ruleForm.idFront = response.data
	  },
	  uploadSuccessB(response){
	      this.ruleForm.idBehind = response.data
	  },
	  async Info() {
	  	let { status, data } = await Personal.info();
	  	if (status) {
	  		if(data.code == 0) {
	  			this.ruleForm = data.data;
	  			if(this.ruleForm.auditStatus != 0) {
	  				this.isDisabled = true;
	  			}else {
	  				this.isDisabled = false;
	  			}
	  		}
	  	}
	  },
	  // async Info() {
	  // 		let { status, data } = await Personal.info();
	  // 		if (status) {
			// 	this.ruleForm = data.data;
			// 	this.pageform = data.data;
			// 	if(this.ruleForm.status == 3 || this.ruleForm.status == 5) {
			// 		this.isDisabled = true;
			// 	}
	  // 		}
	  // 	},
	  async handleRealname() {
	  	this.ruleForm.truename = this.ruleForm.truename;
	  	this.ruleForm.idNo = this.ruleForm.idNo;
	  	let {status,data} = await Personal.realname(this.ruleForm);
	  	if (status) {
	  		if (data.code == 0) {
	  			this.$message.success('操作成功');
	  			this.Info();
	  		} else {
	  			this.$message.error(data.message);
	  		}
	  	}
	  },
		// async handleRealname() {
		// 	let { status, data } = await Personal.realname(this.ruleForm);
		// 	if(status) {
		// 		if(data.code == 200) {
		// 			this.$message.success(data.data);
		// 			this.Info();
		// 		}else {
		// 			this.$message.error(data.message);
		// 		}
		// 	}
		// },
	
  },
    computed:{
		uploadDisabled:function() {
			return this.imagelist.length >0
		},
		uploadUrlF() {
			return this.$base_url + 'api/v1/upload/id'
		},
		uploadUrlB() {
			return this.$base_url + 'api/v1/upload/id'
		},
		postData(){
		    return {token:localStorage.getItem('token')}
		}
    },
  }
</script>

<style lang="less">
	.authentication {
		body {
				margin: 0;
				padding: 0;
				background-color: #F0F3FA;
			}
			.el-breadcrumb{
				padding: 30px;
				.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
					color: #999999;
					font-weight: 500;
				}
				.el-breadcrumb__item:last-child .el-breadcrumb__inner {
					color: #1493FA;
				}
		    }
		    .authentication-box {
		        height: 933px;
		        margin: 0 20px;
		        background-color: #ffffff;
		        .top {
		            height: 93px;
		            background-color: #fff;
		            display: flex;
		            align-items: center;
					margin: 0 25px;
		            .el-alert--warning.is-light {
		                height: 48px;
		            }
		        }
		        .middle {
		            height: 58px;
		            background-color: #F8F8F8;
		            padding-top: 15px;
		            padding-left: 30px;
		            box-sizing: border-box;
		            .title {
		                font-size: 20px;
						color: #333333;
						font-weight: 600;
						border-left: 3px solid #1493FA;
		                padding-left: 10px;
		                margin-top: 20px;
		            }
		        }
		        .content {
		            .authentication-form {
		                margin-top: 39px;
		                .el-form-item__content {
		                    margin-left: 130px!important;
		                }
		                .el-form-item {
		                    margin-left: 30px;
		                }
		            }
		            
		        }
		        .fixed-bottom {
		            position: fixed;
		            bottom: 0;
		            right: 38px;
		            width: 86.5%;
		            height: 75px;
		            background-color: #fff;
		            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
		            align-items: center;
		            justify-content: center;
		            display: flex;
		        }
		    }
		    .hidefront .el-upload--picture-card {
		  display: none;
		}
		.hideback .el-upload--picture-card {
		  display: none;
		}
		.el-upload-list__item.is-success .el-upload-list__item-status-label {
		  display: none;
		}
		.el-upload--picture-card {
		  width: 252px;
		  height: 170px;
		  line-height: 170px;
		  margin-top: 20px;
		  i,span {
		    vertical-align: middle;
		    color: #8c939d;
		  }
		}
		.el-upload-list--picture-card .el-upload-list__item {
		  width: 252px;
		  height: 170px;
		  line-height: 170px;
		  margin-top: 20px;
		}
		.front {
		  display: flex;
		  .text {
		    width: 304px;
		    color: #999999;
		    font-size: 16px;
		    line-height: 26px;
		    margin: auto 20px;
		  }
		}
		.back {
		  display: flex;
		  .text {
		    width: 304px;
		    color: #999999;
		    font-size: 16px;
		    line-height: 26px;
		    margin: auto 20px;
		  }
		}
		.el-table__header {
			width: 100%!important;
		}
		.el-table__body {
			width: 100%!important;
		}
	}
    
</style>